.page {

}

.header {
  height: 54px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  ion-toolbar {
    height: 54px;
    --background: #fff;
    --border-width: 0;
    display: flex;
    align-items: center;

    ion-title {
      font-family: 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #1F2128;
    }
  }
}
.contentBox {
  overflow: hidden;
  padding: 0 16px;
  width: 100%;
  height: 100%;
  .contentTitle {
    margin-top: 26px;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .inputBox {
    margin-top: 24px;
    .inputHeader {
      text-transform: capitalize;
      font-family: 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #616161;
    }
    .input {
      margin-top: 8px;
      background: #F0F0F0;
      border-radius: 3px;

      input {
        padding-left: 12px;
      }

    }
  }
}
.successBox {
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal;
  img {
    margin-top: 68px;
    width: 82px;
    height: 82px;
  }
  .successTitle {
    margin-top: 28px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    color: #212121;
  }
  .successSubTitle {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #7E7E7E;

  }
}

.footer {
  padding: 0 16px;
  .actionButton {
    height: 32px;
    width: 100%;
    margin-bottom: 26px;
    --border-radius: 44px;
    --background: #605DEC;
    --background-activated: #fff;
    --background-activated-opacity: 0.3;
    --background-hover: #605DEC;
    --background-hover-opacity: 0.1;

    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
  }
}